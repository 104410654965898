import React from "react";

const AudioPlayer = ({ link }) => {
  return (
    <div className="audio-container">
      <audio controls>
        <source src={link} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
};

export default AudioPlayer;
