import { appContext as AppContext } from "./context";
import React, { useEffect, useState, useRef } from "react";
import { isTabletViewOrLess } from "../constants/DeviceInfo";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseRoute } from "../constants/RouteConstants";
import useGetDetailedScheduleById from "../hooks/useGetDetailedScheduleById";
import useGetLiveStreamUrl from "../hooks/useGetLiveStreamUrl";
import FixtureConstants from "../constants/FixtureConstants";
import getRandomNumber from "../functions/getRandomNumber";
import {
  FETCH_FIXTURE_BY_ID_FROM_URI_FIXTURE_ID_PARAM_NAME,
  FIXTURES_REFRESH_MAX_INTERVAL_IN_SEC,
  FIXTURES_REFRESH_MIN_INTERVAL_IN_SEC,
  LIVE_DETAILED_FIXTURE_REFRESH_MAX_INTERVAL_IN_SEC,
  LIVE_DETAILED_FIXTURE_REFRESH_MIN_INTERVAL_IN_SEC,
} from "../constants/EnvConstants";
import useGetSchedules from "../hooks/useGetSchedules";
import isObjectEmpty from "../functions/isObjectEmpty";

const AppState = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const getLiveStreamUrl = useGetLiveStreamUrl();
  const getAllFixtures = useGetSchedules();
  const getDetailedScheduleById = useGetDetailedScheduleById();
  const [selectedFixtureId, setSelectedFixtureId] = useState(0);
  const [liveStreamUrl, setLiveStreamUrl] = useState({});
  const [selectedFixture, setSelectedFixtureStateFunction] = useState({});
  const [selectedFixtureDetailed, setSelectedFixtureDetailed] = useState({});
  const [isExpanded, setIsExpanded] = useState(true);
  const [isAllFixturesLoading, setIsAllFixturesLoading] = useState(true);
  const [allFixtures, setAllFixtures] = useState([]);
  const [allFixturesToDisplay, setAllFixturesToDisplay] = useState([]);

  const updateSelectedFixtureInAllFixtures = (fixture) => {
    const id = fixture?.id;
    const team0Detailed = Array.isArray(fixture?.teamsDetails)
      ? fixture?.teamsDetails[0]
      : {};
    const team1Detailed = Array.isArray(fixture?.teamsDetails)
      ? fixture?.teamsDetails[1]
      : {};
    const teams = [
      {
        teamName: team0Detailed?.teamName,
        inning: team0Detailed?.inningDetails,
        teamId: team0Detailed?.teamId,
        flagImageName: team0Detailed?.flagImageName,
      },
      {
        teamName: team1Detailed?.teamName,
        inning: team1Detailed?.inningDetails,
        teamId: team1Detailed?.teamId,
        flagImageName: team1Detailed?.flagImageName,
      },
    ];
    const updatedFixtures = allFixturesToDisplay.map((oneOfFixtures) =>
      oneOfFixtures.id === id
        ? { ...oneOfFixtures, teams: teams }
        : oneOfFixtures
    );
    // console.log("Updating Fixtures: From Detailed");
    // console.log(updatedFixtures);
    setAllFixturesToDisplay(updatedFixtures);
  };

  const updateDetailedSelectedFixtureFromAllFixtures = (currentFixture) => {
    if (!currentFixture?.teams) {
      return;
    }
    const currentFixtureTeam0InningsDetais = currentFixture?.teams[0]?.inning;
    const currentFixtureTeam1InningsDetais = currentFixture?.teams[1]?.inning;
    const selectedFixtureTeam0Detais = selectedFixtureDetailed?.teamsDetails[0];
    const selectedFixtureTeam1Detais = selectedFixtureDetailed?.teamsDetails[1];
    const selectedFixtureTeam0InningsDetais =
      selectedFixtureTeam0Detais?.inningDetails;
    const selectedFixtureTeam1InningsDetais =
      selectedFixtureTeam1Detais?.inningDetails;

    const detailedFixture = {
      ...selectedFixtureDetailed,
      teamsDetails: [
        {
          ...selectedFixtureTeam0Detais,
          inningDetails: {
            ...selectedFixtureTeam0InningsDetais,
            totalRuns: currentFixtureTeam0InningsDetais?.totalRuns,
            actualTotalRuns: currentFixtureTeam0InningsDetais?.actualTotalRuns,
            oversBowled: currentFixtureTeam0InningsDetais?.oversBowled,
            ballsBowled: currentFixtureTeam0InningsDetais?.ballsBowled,
            wicketsLost: currentFixtureTeam0InningsDetais?.wicketsLost,
            currentSession: currentFixtureTeam0InningsDetais?.currentSession,
            status: currentFixtureTeam0InningsDetais?.status,
          },
        },
        {
          ...selectedFixtureTeam1Detais,
          inningDetails: {
            ...selectedFixtureTeam1InningsDetais,
            totalRuns: currentFixtureTeam1InningsDetais?.totalRuns,
            actualTotalRuns: currentFixtureTeam1InningsDetais?.actualTotalRuns,
            oversBowled: currentFixtureTeam1InningsDetais?.oversBowled,
            ballsBowled: currentFixtureTeam1InningsDetais?.ballsBowled,
            wicketsLost: currentFixtureTeam1InningsDetais?.wicketsLost,
            currentSession: currentFixtureTeam1InningsDetais?.currentSession,
            status: currentFixtureTeam1InningsDetais?.status,
          },
        },
      ],
    };
    // console.log("Updating Detailed Fixtures: From All Fixtures");
    // console.log(detailedFixture);
    setSelectedFixtureDetailed(detailedFixture);
  };

  const updateSelectedFixture = (fixture) => {
    setSelectedFixtureDetailed(fixture);
    if (fixture?.status === FixtureConstants.FIXTURE_STATUS.IN_PROGRESS) {
      updateSelectedFixtureInAllFixtures(fixture);
    }
  };

  const getDetailedFixture = (id) => {
    getDetailedScheduleById(id, updateSelectedFixture);
  };

  const setSelectedFixture = (fixture) => {
    if (fixture?.id !== selectedFixtureDetailed?.id) {
      setSelectedFixtureStateFunction(fixture);
      getDetailedFixture(fixture?.id);
    }
    if (isTabletViewOrLess) {
      setIsExpanded(false);
    }
    navigate(BaseRoute);
  };

  useEffect(() => {
    const updateInTime =
      getRandomNumber(
        LIVE_DETAILED_FIXTURE_REFRESH_MIN_INTERVAL_IN_SEC,
        LIVE_DETAILED_FIXTURE_REFRESH_MAX_INTERVAL_IN_SEC
      ) * 1000;
    if (
      FixtureConstants.FIXTURE_STATUS.IN_PROGRESS === selectedFixture?.status
    ) {
      // console.log(`Refeshing DEtailed fixture in ${updateInTime / 1000}s.`);
      setTimeout(() => {
        getDetailedFixture(selectedFixture?.id);
      }, updateInTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFixtureDetailed]);

  useEffect(() => {
    if (selectedFixture?.id !== selectedFixtureId) {
      setSelectedFixtureId(selectedFixture?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFixture]);

  useEffect(() => {
    if (
      selectedFixtureId !== 0 &&
      selectedFixture?.id === selectedFixtureId &&
      FixtureConstants.FIXTURE_STATUS.IN_PROGRESS === selectedFixture?.status
    ) {
      getLiveStreamUrl(selectedFixtureId, setLiveStreamUrl);
    }
    if (
      !isObjectEmpty(selectedFixture) &&
      !isObjectEmpty(selectedFixtureDetailed)
    ) {
      updateDetailedSelectedFixtureFromAllFixtures(selectedFixture);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFixtureId]);

  useEffect(() => {
    getAllFixtures((fixtures) => {
      setIsAllFixturesLoading(false);
      if (Array.isArray(fixtures)) {
        setAllFixtures(fixtures);
      }
    });

    // Fetch Detailed Fixture from URL (if any)
    const queryParameters = new URLSearchParams(location.search);
    const fixtureId =
      queryParameters.get(FETCH_FIXTURE_BY_ID_FROM_URI_FIXTURE_ID_PARAM_NAME) ||
      0;
    if (fixtureId !== 0) {
      setSelectedFixture({
        id: +fixtureId,
      });
      // getDetailedFixture(fixtureId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allFixtures?.length === 0) {
      return;
    }
    if (!isObjectEmpty(selectedFixture)) {
      const currentfixture = allFixtures.filter(
        (fixture) => fixture?.id === selectedFixture?.id
      )[0];
      if (
        currentfixture &&
        (currentfixture.status !== selectedFixture?.status ||
          currentfixture.status === FixtureConstants.FIXTURE_STATUS.IN_PROGRESS)
      ) {
        setSelectedFixtureStateFunction(currentfixture);
      }
    }
    const updateInTime =
      getRandomNumber(
        FIXTURES_REFRESH_MIN_INTERVAL_IN_SEC,
        FIXTURES_REFRESH_MAX_INTERVAL_IN_SEC
      ) * 1000;
    // console.log(`Refeshing all fixtures in ${updateInTime / 1000}s.`);
    setTimeout(() => {
      getAllFixtures(setAllFixtures);
    }, updateInTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFixtures]);

  useEffect(() => {
    setAllFixturesToDisplay(allFixtures);
  }, [allFixtures]);

  // Create a ref for the News div
  const targetRef = useRef(null);

  // Function to handle the button click and scroll to the News div
  const scrollToNewsDiv = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("targetRef.current is null");
    }
  };
  return (
    <AppContext.Provider
      value={{
        selectedFixture,
        setSelectedFixture,
        isExpanded,
        setIsExpanded,
        selectedFixtureDetailed,
        liveStreamUrl,
        isAllFixturesLoading,
        allFixtures: allFixturesToDisplay,
        scrollToNewsDiv,
        targetRef,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
