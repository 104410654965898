import React, { useContext, useEffect, useState } from "react";
import useGetAllSchedules from "../hooks/useGetAllSchedules";
import Fixture from "../components/Fixture";
import landingBackground from "../assets/images/landingBackground.jpg";
// import BackToHomeHeader from "../components/BackToHomeHeader";
import { appContext } from "../context/context";
import Header from "../components/landing/Header";

const Schedule = () => {
  const appState = useContext(appContext);
  const setSelectedFixture = appState.setSelectedFixture;
  const getAllSchedules = useGetAllSchedules();
  const [fixtures, setFixtures] = useState([]);
  const allSchedulesSessionStorageItemName = "all-fixtures-list";

  const setAllSchedulesCustom = (data) => {
    // Sort the data by scheduleDate and scheduledTime in ascending order
    const sortedData = data.sort((a, b) => {
      const dateA = new Date(`${a.scheduleDate}T${a.scheduledTime}`);
      const dateB = new Date(`${b.scheduleDate}T${b.scheduledTime}`);
      return dateA - dateB;
    });

    setFixtures(sortedData);
    // Convert data to JSON string
    const allFixturesString = JSON.stringify(sortedData);
    // Save to session storage
    sessionStorage.setItem(
      allSchedulesSessionStorageItemName,
      allFixturesString
    );
  };
  useEffect(() => {
    const allFixturesString = sessionStorage.getItem(
      allSchedulesSessionStorageItemName
    );
    if (allFixturesString) {
      const newsDataArray = JSON.parse(allFixturesString);
      // Ensure the fixtures are sorted even if loaded from session storage
      const sortedData = newsDataArray.sort((a, b) => {
        const dateA = new Date(`${a.scheduleDate}T${a.scheduledTime}`);
        const dateB = new Date(`${b.scheduleDate}T${b.scheduledTime}`);
        return dateA - dateB;
      });
      setFixtures(sortedData);
    } else {
      getAllSchedules(setAllSchedulesCustom);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section
      className="bg-opacity-80 w-full min-h-screen pb-[5em]"
      style={{
        backgroundImage: `url(${landingBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "top",
      }}
    >
      {/* <BackToHomeHeader title={"Schedules"} navigateBack={true} /> */}
      <div className="-fixed top-0- w-full z-[10000] ">
        <Header selectedPage={"schedule"} />
      </div>
      {fixtures.length > 0 ? (
        <div
          className={
            "grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 text-[0.8em] tablet:text-[1em] rounded-xl tablet:mx-[12.8em] mx-[1.9em] mt-[3.6em] border-[1px] border-white"
          }
        >
          {fixtures &&
            Array.isArray(fixtures) &&
            fixtures.map((fixture, i) => (
              <Fixture
                key={i}
                fixture={fixture}
                isExpanded={true}
                isSelected={false}
                textColorClass={"text-[#ffffffE6]"}
                bgClass={i % 2 === 0 ? "bg-transparent" : "bg-[#ffffff08]"}
                className={"min-h-[18em]"}
                onClick={() => setSelectedFixture(fixture)}
              />
            ))}
        </div>
      ) : (
        <div className="flex justify-center items-center text-white mt-[10em] text-[2em]">
          Loading...
        </div>
      )}
    </section>
  );
};

export default Schedule;
