import React from "react";
// import TopBar from "./TopBar";
import HeaderFixtures from "./HeaderFixtures";
import {
  ScheduleRoute,
  PointsTableRoute,
  VideosRoute,
  LandingRoute,
  NewsRoute,
} from "../../constants/RouteConstants";
import { useNavigate } from "react-router-dom";
// import { appContext } from "../../context/context";

function Header({ selectedPage }) {
  // const appState = useContext(appContext);
  const navigate = useNavigate();

  const navigateToLanding = () => {
    navigate(LandingRoute);
  };
  const navigateToNews = () => {
    navigate(NewsRoute);
  };

  const navigateToSchedule = () => {
    navigate(ScheduleRoute);
  };
  // eslint-disable-next-line
  const navigateToPointsTable = () => {
    navigate(PointsTableRoute);
  };
  const navigateToVideos = () => {
    navigate(VideosRoute);
  };
  const selectedClass =
    "border-b-[3px] border-b-[#19AAF8] text-[#19AAF8] font-[600] py-[1em] tablet:py-[2em] w-[25%] cursor-pointer hover:opacity-80";
  const notSelectedClass =
    "border-x-[1px] border-x-[#6C74991A] border-b-[1px] border-b-[#6C74991A] py-[1em] tablet:py-[2em] w-[25%] cursor-pointer hover:opacity-80 hover:underline";

  const navItems = [
    {
      name: "Home",
      isSelected: selectedPage === "Home",
      onClick: navigateToLanding,
    },
    {
      name: "News",
      isSelected: selectedPage === "news",
      onClick: navigateToNews,
    },
    // {
    //   name: "Points Table",
    //   isSelected: selectedPage === "pointsTable",
    //   onClick: navigateToPointsTable,
    // },
    {
      name: "Schedule",
      isSelected: selectedPage === "schedule",
      onClick: navigateToSchedule,
    },
    {
      name: "Videos",
      isSelected: selectedPage === "videos",
      onClick: navigateToVideos,
    },
  ];

  return (
    <>
      <HeaderFixtures />
      <div className="mobile:text-[1.6em] text-[1.1em] font-[500] text-[#6C7499] flex justify-between text-center ">
        {navItems.map((item, i) => (
          <div
            key={i}
            className={item.isSelected ? selectedClass : notSelectedClass}
            onClick={item.onClick}
          >
            {item.name}
          </div>
        ))}
      </div>
    </>
  );
}

export default Header;
