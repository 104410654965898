import React, { useEffect, useState } from "react";
import useGetPointsTable from "../hooks/useGetPointsTable";
import ImageComponent from "./ImageComponent";
const PointsTable = ({ size }) => {
  const getPointsTable = useGetPointsTable();
  const [positions, setPositions] = useState([]);
  useEffect(() => {
    getPointsTable(setPositions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const headers = [
    {
      headerName: "Pos",
    },
    {
      headerName: "Team",
      className:
        "col-span-2 px-0 mobile:px-2 tablet:px-3 laptop:px-5 text-center mobile:text-left",
    },
    {
      headerName: "M",
    },
    {
      headerName: "W",
    },
    {
      headerName: "L",
    },
    {
      headerName: "NRR",
    },
    {
      headerName: "Pts",
    },
  ];
  return positions.length > 0 ? (
    <>
      {positions.length < 3 ? (
        <div className="laptop:text-[3.0em] tablet:text-[2.8em] mobile:text-[2.6em] text-[1.3em]  text-[#FFFFFF] mb-4 mt-4">
          Super Eight
        </div>
      ) : (
        <div className="laptop:text-[3.0em] tablet:text-[2.8em] mobile:text-[2.6em] text-[1.2em]  text-[#FFFFFF] mb-4 mt-4 ">
          Group Stage
        </div>
      )}
      {positions.map((group, groupIndex) => {
        const limit = size || group.teamDetails.length;
        return (
          <div key={groupIndex}>
            <div className="laptop:text-[1.8em] font-semibold tablet:text-[1.8em] mobile:text-[1.6em] text-[1.2em]  text-[#FFFFFF] ">
              {group.groupName}
            </div>
            <div
              className={` bg-[#FFFFFF1A] rounded-xl  mt-[1.9em] mb-[3.1em] tablet:px-[2.2em] px-[1.2em] pt-[3em] pb-[3em]  ${
                limit % 2 === 0 && "pb-[3em]"
              }`}
            >
              <div className="grid grid-cols-8 tablet:text-[1.6em] text-[1.2em] font-[300] text-center text-white border-b-[#FFFFFF1A] border-b-[1px] pb-4 ">
                {headers.map((header, headerIndex) => (
                  <div
                    key={headerIndex}
                    className={`${header.className || ""}`}
                  >
                    {header.headerName}
                  </div>
                ))}
              </div>
              {group.teamDetails &&
                group.teamDetails.slice(0, limit).map((team, teamIndex) => (
                  <div
                    key={teamIndex}
                    className={`grid grid-cols-8 items-center tablet:text-[1.6em] text-[1em] font-[300] text-center text-white ${
                      teamIndex % 2 !== 0 && "rounded-[10px] bg-[#FFFFFF1A] "
                    }`}
                  >
                    <div className={`py-[2em]`}>{teamIndex + 1}</div>
                    <div
                      className={`py-[2em] px-0 mobile:px-2 tablet:px-3 laptop:px-5 col-span-2 flex justify-start items-center flex-col mobile:flex-row mobile:gap-3 gap-2`}
                    >
                      <div className="min-w-[2em] w-[2em] border-[1px] rounded-[2px] border-white">
                        <ImageComponent name={team?.flagFilename} />
                      </div>
                      <div>{team?.teamName}</div>
                    </div>
                    <div className={`py-[2em]`}>{team?.totalMatches}</div>
                    <div className={`py-[2em]`}>{team?.matchesWon}</div>
                    <div className={`py-[2em]`}>{team?.matchesLose}</div>
                    <div className={`py-[2em]`}>{team?.netRunRate}</div>
                    <div className={`py-[2em]`}>{team?.totalPoints}</div>
                  </div>
                ))}
            </div>
          </div>
        );
      })}
    </>
  ) : (
    <div className="flex justify-center items-center text-white mt-[10em] mobile:text-[2em] text-[1.4em]">
      Loading...
    </div>
  );
};
export default PointsTable;
