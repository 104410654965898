import React, { useEffect, useState } from "react";
import Arrow from "../assets/svgs/rightArrow.svg";
import NewsCard from "./NewsCard";
import useGetNews from "../hooks/useGetNews";
import { MAX_NO_OF_NEWS_TO_SHOW_WHEN_NEWS_SECTION_IS_COLLAPSED } from "../constants/EnvConstants";

function News({ isViewAll, handleViewAll, showTitle, hiddenVideoTitle }) {
  const getNewsData = useGetNews();
  const [newsData, setNewsData] = useState([]);
  const newsListSessionStorageItemName = "news-list";
  const showTitleVar = showTitle !== -1;

  const setNewsDataCustom = (data) => {
    setNewsData(data);
    // Convert data to JSON string
    const newsDataString = JSON.stringify(data);
    // Save to session storage
    sessionStorage.setItem(newsListSessionStorageItemName, newsDataString);
  };

  useEffect(() => {
    const newsDataString = sessionStorage.getItem(
      newsListSessionStorageItemName
    );
    if (newsDataString) {
      const newsDataArray = JSON.parse(newsDataString);
      setNewsData(newsDataArray);
    } else {
      getNewsData(setNewsDataCustom);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMapTypeOfNews = (mapType) => {
    if (mapType === 1) {
      return newsData.map((news, i) => <NewsCard key={i} news={news} />);
    } else {
      const filteredNews = newsData.filter(
        (news) => news.title !== hiddenVideoTitle
      );
      return filteredNews
        .slice(0, MAX_NO_OF_NEWS_TO_SHOW_WHEN_NEWS_SECTION_IS_COLLAPSED)
        .map((news, i) => <NewsCard key={i} news={news} />);
    }
  };
  // const handleText = () => {
  //   if (isViewAll) {
  //     return "View Less";
  //   } else {
  //     return "View All";
  //   }
  // };
  return (
    <div>
      <div className="flex items-center space-x-3">
        {showTitleVar && (
          <div className="laptop:text-[3.6em] tablet:text-[3em] mobile:text-[2.8em] text-[1.4em]  text-[#FFFFFF]">
            News
          </div>
        )}
        {handleViewAll && (
          <button
            className="flex items-center gap-x-3 cursor-pointer hover:opacity-85 laptop:text-[1.6em] tablet:text-[1.4em] mobile:text-[1.3em] text-[1em]  text-[#FFFFFF80] "
            onClick={() => handleViewAll(1)}
          >
            View All
            {/* {handleText()} */}
            <img className="mobile:w-auto w-2" src={Arrow} alt="arrow" />
          </button>
        )}
      </div>
      <div
        id="news-points-table"
        className="mobile:mt-[3.6em] mt-[2em] mobile:mb-[2em]"
      >
        {newsData.length === 0 ? (
          <div className="flex justify-center items-center text-white my-[5em] mobile:text-[2em] text-[1.3em]">
            Loading...
          </div>
        ) : (
          <div className="grid desktop:grid-cols-4 tablet:grid-cols-3 mobile:grid-cols-2 grid-col-1 gap-6 mt-0 mobile:gap-y-[5em] gap-y-[3em]  w-full justify-between">
            {isViewAll ? handleMapTypeOfNews(1) : handleMapTypeOfNews(2)}
          </div>
        )}
      </div>
    </div>
  );
}

export default News;
