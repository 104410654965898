import React, { useEffect, useRef } from "react";
import BallByBallScore from "./BallByBallScore";
import BattingGraph from "./BattingGraph";
import BallByBallConstants from "../constants/BallByBallConstants";
import TeamScoreStats from "./TeamScoreStats";

const MatchStats = ({ innings }) => {
  const ballByBallDataRef = useRef(null);

  useEffect(() => {
    const ballByBallDataContainer = ballByBallDataRef.current;
    if (ballByBallDataContainer) {
      ballByBallDataContainer.scrollLeft = ballByBallDataContainer.scrollWidth;
    }
  }, []);
  if (!innings) {
    return <></>;
  }
  const ballByBallData = innings?.ballByBall;
  const handleColorByBall = (ballScore) => {
    if (ballScore.toUpperCase() === BallByBallConstants.BALL_TYPE.WICKET) {
      return "bg-[#4285F4] border-none";
    } else if (ballScore === BallByBallConstants.BALL_TYPE.DOT_BALL) {
      return "bg-none border-[#44528A]";
    } else {
      return "bg-[#384371] border-none";
    }
  };

  return (
    <div className="rounded-xl">
      <div
        ref={ballByBallDataRef}
        id="recent-balls"
        className="flex gap-3 mobile:text-[1.4em] text-[1.1em] overflow-x-scroll tablet:mx-[3.4em] mx-[1.9em] mt-[3.1em] tablet:px-[2.2em] px-[1.2em] tablet:pt-[3em] pt-[1.3em] pb-[1em] hide-scroll-bar"
      >
        <BallByBallScore
          ballByData={ballByBallData}
          handleColorByBall={handleColorByBall}
        />
      </div>
      <div
        id="batting-comparison-graph"
        className="rounded-xl tablet:mx-[3.4em] mobile:mx-[1em] mt-[0.1em]  tablet:pt-[3em] pt-[1.3em] mobile:pb-[4em]"
      >
        <BattingGraph />
      </div>
      <TeamScoreStats />
    </div>
  );
};

export default MatchStats;
