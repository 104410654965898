import React from "react";
import Header from "../components/landing/Header";
import News from "../components/News";

function AllNews() {
  return (
    <div
      style={{
        background: "linear-gradient(180deg, #212539 0%, #354070 100%) ",
      }}
      className="bg-black min-h-screen pb-[2em] "
    >
      <div className="-fixed top-0- w-full z-[10000] ">
        <Header selectedPage={"news"} />
      </div>
      <div className="tablet:px-[10em] mobile:px-[6em] px-[3em] rounded-xl  mobile:mt-[3.1em] mt-[2.5em]  tablet:pt-[3em] mobile:pt-[1.3em] pb-[4em]">
        <News isViewAll={true} handleViewAll={null} />
      </div>
    </div>
  );
}

export default AllNews;
