import axios from "axios";
import {
  BASE_URL,
  GET_LIVE_STREAM_URL_BY_FIXTURE_ID_ENDPOINT,
  GET_LIVE_STREAM_URL_BY_FIXTURE_ID_ENDPOINT_ID_PARAM_IDENTIFIER,
} from "../constants/APIConstant";

const useGetLiveStreamUrl = () => {
  const getLiveStreamUrl = (id, callback) => {
    const path = BASE_URL + GET_LIVE_STREAM_URL_BY_FIXTURE_ID_ENDPOINT;
    const pathWithModifiedParam = path.replace(
      GET_LIVE_STREAM_URL_BY_FIXTURE_ID_ENDPOINT_ID_PARAM_IDENTIFIER,
      id
    );
    axios
      .get(pathWithModifiedParam)
      .then((response) => {
        if (Array.isArray(response?.data?.data)) {
          callback(response?.data?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Failed To Load Live Strem Url");
      });
  };
  return getLiveStreamUrl;
};

export default useGetLiveStreamUrl;
