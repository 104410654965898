import React from "react";
import FIXTURE_CONSTANTS from "../constants/FixtureConstants";
import getRelativeDate from "../functions/getRelativeDate";
import getFormattedTime from "../functions/getFormattedTime";
import ImageComponent from "./ImageComponent";

const Fixture = ({
  fixture,
  isSelected,
  isExpanded,
  bgClass,
  textColorClass,
  onClick,
  className,
  style,
}) => {
  const matchIsScheduled =
    fixture.status === FIXTURE_CONSTANTS.FIXTURE_STATUS.SCHEDULED ||
    fixture.status ===
      FIXTURE_CONSTANTS.FIXTURE_STATUS.SCHEDULED_SQUAD_SELECTED;
  const matchInProgress =
    fixture.status === FIXTURE_CONSTANTS.FIXTURE_STATUS.IN_PROGRESS;
  const matchIsCompleted =
    fixture.status === FIXTURE_CONSTANTS.FIXTURE_STATUS.COMPLETED;
  const textColor = textColorClass || "text-[#212539]";
  const seriesTitle = fixture.seriesName ? fixture.seriesName : "";
  const matchTitle = fixture?.title;

  //SliceString from ,
  const commaIndex = matchTitle.indexOf(",");
  //const firstPart = text.substring(0, commaIndex);
  const restPart = matchTitle.substring(commaIndex + 1);
  return (
    <div
      style={style}
      className={`${
        isExpanded ? "opacity-100" : "opacity-0"
      } transition-all duration-1000 delay-500 p-[2.2em] border-b-[#21253933] border-b-[1px] ${
        isSelected ? "bg-[#19AAF821]" : bgClass || "bg-white"
      } ${className || ""} `}
    >
      <div
        className="cursor-pointer space-y-[1em] tablet:space-y-[1.5em]"
        onClick={onClick}
      >
        <div className="flex justify-between text-[1em] tablet:text-[1.3em]">
          <div
            className={`${textColor} opacity-50 overflow-hidden whitespace-nowrap font-semibold`}
          >
            {seriesTitle}
            <div className="font-[400]"> {restPart}</div>
          </div>
          <div
            className={`${textColor} opacity-80 overflow-hidden whitespace-nowrap`}
          >
            {matchInProgress ? (
              <div className="flex gap-2 items-center font-[600]">
                <div className="size-2 rounded-full bg-green-500 blinking"></div>{" "}
                In Progress
              </div>
            ) : (
              getRelativeDate(fixture.scheduleDate)
            )}
          </div>
        </div>
        {fixture?.teams.map((team, index) => {
          const otherTeamIndex = index === 0 ? 1 : 0;
          const teamIsBattingSecond =
            fixture?.firstBattingTeamId ===
            fixture.teams[otherTeamIndex]?.teamId;
          const isDlsApplied = fixture?.dslApplied;
          const showDlsTarget = teamIsBattingSecond && isDlsApplied;
          if (matchIsScheduled) {
            return (
              <TeamInfo
                key={index}
                team={team}
                textColorClass={textColorClass}
              />
            );
          } else if (matchInProgress) {
            return (
              <InProgressMatchTeamInfo
                key={index}
                team={team}
                totalOvers={fixture?.maxOvers}
                showDlsTarget={showDlsTarget}
                reducedTarget={showDlsTarget ? fixture?.reducedTarget : null}
                textColorClass={textColorClass}
              />
            );
          } else if (matchIsCompleted) {
            return (
              <CompletedMatchTeamInfo
                key={index}
                team={team}
                totalOvers={fixture?.maxOvers}
                showDlsTarget={showDlsTarget}
                reducedTarget={showDlsTarget ? fixture?.reducedTarget : null}
                isWinner={team?.teamId === fixture?.winnerTeamId}
                textColorClass={textColorClass}
              />
            );
          } else {
            return <></>;
          }
        })}
        {matchIsScheduled ? (
          <div
            className={`text-[1em] tablet:text-[1.4em] ${textColor} opacity-60 overflow-hidden whitespace-nowrap`}
          >
            Starts at {getFormattedTime(fixture.scheduledTime)}
          </div>
        ) : matchIsCompleted ? (
          <div
            className={`text-[1em] tablet:text-[1.4em] ${textColor} opacity-60 overflow-hidden whitespace-nowrap`}
          >
            {fixture.matchResult}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const TeamInfo = ({ team, textColorClass }) => {
  const textColor = textColorClass || `text-[#212539] opacity-90`;
  return (
    <div className="flex items-center gap-[0.8em]  overflow-hidden whitespace-nowrap">
      <div className="w-[3.7em] h-[2.55em]">
        <ImageComponent
          name={team?.flagImageName}
          className="w-[3em] h-[1.7em] tablet:w-[3.7em] tablet:h-[2.55em]"
        />
      </div>
      <p
        className={`${textColor} text-[1.25em] tablet:text-[1.6em] overflow-hidden whitespace-nowrap`}
      >
        {team.teamName}
      </p>
    </div>
  );
};

const InProgressMatchTeamInfo = ({
  team,
  totalOvers,
  showDlsTarget,
  reducedTarget,
  textColorClass,
}) => {
  return (
    <div className="flex justify-between items-start">
      <TeamInfo team={team} textColorClass={textColorClass} />
      <TeamScoreinfo
        inning={team.inning}
        totalOvers={totalOvers}
        showDlsTarget={showDlsTarget}
        reducedTarget={reducedTarget}
        textColorClass={textColorClass}
      />
    </div>
  );
};

const CompletedMatchTeamInfo = ({
  team,
  totalOvers,
  showDlsTarget,
  reducedTarget,
  isWinner,
  textColorClass,
}) => {
  const textColorClassChild = isWinner ? "text-[#4285F4]" : textColorClass;
  return (
    <div className="flex justify-between items-start">
      <TeamInfo team={team} textColorClass={textColorClassChild} />
      <TeamScoreinfo
        inning={team.inning}
        totalOvers={totalOvers}
        textColorClass={textColorClass}
        showDlsTarget={showDlsTarget}
        reducedTarget={reducedTarget}
      />
    </div>
  );
};

const TeamScoreinfo = ({
  inning,
  totalOvers,
  textColorClass,
  showDlsTarget,
  reducedTarget,
}) => {
  if (!inning) {
    return <></>;
  }
  const textColor = textColorClass || "text-[#212539] opacity-90";
  const content = (inning) => {
    return (
      <div>
        <div
          className={`${textColor} text-[1.3em] tablet:text-[1.6em] overflow-hidden whitespace-nowrap`}
        >
          {inning?.actualTotalRuns || inning?.totalRuns || 0}
          {inning.wicketsLost || inning?.wicketsLost === 0
            ? `/${inning.wicketsLost}`
            : ""}{" "}
          ({inning?.oversBowled || 0}.{inning?.ballsBowled || 0}
          {totalOvers ? `/${totalOvers}` : ""})
        </div>
        {showDlsTarget && (
          <div className={`${textColor} text-[1.3em] text-right`}>
            Target {reducedTarget}
          </div>
        )}
      </div>
    );
  };
  if (Array.isArray(inning)) {
    return (
      <div className="space-y-3">
        {inning.map((inn, index) => (
          <div key={index} className="flex justify-end">
            {content(inn)}
          </div>
        ))}
      </div>
    );
  }
  return content;
};

export default Fixture;
