import axios from "axios";
import {
  BASE_URL,
  GET_YOUTUBE_VIDEO_LINKS_ENDPOINT,
} from "../constants/APIConstant";

const useGetVideos = () => {
  const getVideos = (first, pageSize, callback) => {
    axios
      .get(BASE_URL + GET_YOUTUBE_VIDEO_LINKS_ENDPOINT, {
        params: {
          first: first, // Fetch Latest Videos
          pageSize: pageSize,
        },
      })
      .then((response) => {
        console.log(response);
        if (Array.isArray(response?.data?.data)) {
          callback(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Failed To Load Videos");
      });
  };

  return getVideos;
};

export default useGetVideos;
