import axios from "axios";
import {
  BASE_URL,
  GET_TOTAL_NO_OF_YOUTUBE_VIDEO_LINKS_ENDPOINT,
} from "../constants/APIConstant";

const useGetTotalNoOfVideos = () => {
  const getTotalNoOfVideos = (callback) => {
    axios
      .get(BASE_URL + GET_TOTAL_NO_OF_YOUTUBE_VIDEO_LINKS_ENDPOINT)
      .then((response) => {
        console.log(response);
        callback(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        alert("Failed To fetch totla no of Videos");
      });
  };

  return getTotalNoOfVideos;
};

export default useGetTotalNoOfVideos;
