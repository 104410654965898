import React, { useState } from "react";

const ReadMore = ({ text, limit }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className="tablet:text-[1.8em] mobile:text-[1.6em] text-[1.4em] text-[#ffffff80]">
      {isReadMore ? text.slice(0, limit) + "..." : text}
      {text.length > limit && (
        <span
          onClick={toggleReadMore}
          className="text-[#19AAF880] cursor-pointer font-[550]"
        >
          {isReadMore ? " Read more" : " Show less"}
        </span>
      )}
    </p>
  );
};

export default ReadMore;
