import axios from "axios";
import { BASE_URL, GET_POINTS_TABLE_ENDPOINT } from "../constants/APIConstant";

const useGetPointsTable = () => {
  const getPointsTable = (callback) => {
    axios
      .get(BASE_URL + GET_POINTS_TABLE_ENDPOINT)
      .then((response) => {
        // callback(response?.data?.data);
        if (Array.isArray(response?.data?.data)) {
          callback(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Failed To Load...");
      });
  };
  return getPointsTable;
};

export default useGetPointsTable;
