import axios from "axios";
import {
  BASE_URL,
  GET_FIXTURE_BY_ID_ENDPOINT,
  ID_PARAM_IDENTIFIER,
} from "../constants/APIConstant";

const useGetDetailedScheduleById = () => {
  const getDetailedScheduleById = (id, callback) => {
    const path = BASE_URL + GET_FIXTURE_BY_ID_ENDPOINT;
    const pathWithModifiedParam = path.replace(ID_PARAM_IDENTIFIER, id);
    axios
      .get(pathWithModifiedParam)
      .then((response) => {
        if (response?.data?.data) {
          callback(response?.data?.data);
        } else {
          callback({});
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Failed To Load Fixtures");
      });
  };
  return getDetailedScheduleById;
};

export default useGetDetailedScheduleById;
