import React from "react";

const LightedSection = ({ children, className }) => {
  return (
    <div
      className={`bg-[#FFFFFF1A] rounded-xl tablet:mx-[3.4em]  mt-[3.1em] tablet:px-[2.2em] px-[1.2em] tablet:pt-[3em] pt-[1.3em] pb-[4em] ${
        className || ""
      }`}
    >
      {children}
    </div>
  );
};

export default LightedSection;
