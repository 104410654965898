import React, { useContext, useEffect, useRef } from "react";
import Fixture from "./Fixture";
import { appContext } from "../context/context";
import FIXTURE_CONSTANTS from "../constants/FixtureConstants";
import isObjectEmpty from "../functions/isObjectEmpty";
import getRelativeDate from "../functions/getRelativeDate";

const FixturesList = ({ fixtureClass, fixtureStyle, fixtureBgClass }) => {
  const appState = useContext(appContext);
  const isExpanded = appState.isExpanded;
  const selectedFixture = appState?.selectedFixture;
  const setSelectedFixture = appState.setSelectedFixture;
  const isLoading = appState.isAllFixturesLoading;
  const fixtures = appState?.allFixtures;
  const fixturesRefs = useRef([]);

  const handleFixtureClick = (index, fixture) => {
    if (fixturesRefs.current[index]) {
      const focusedFixture = fixturesRefs.current[index];
      if (focusedFixture) {
        focusedFixture.scrollIntoView({ behavior: "smooth" });
      }
    }
    setSelectedFixture(fixture);
  };

  useEffect(() => {
    const focusedRefLength = Array.isArray(fixturesRefs.current)
      ? fixturesRefs.current.length
      : 0;
    if (fixtures?.length > 0 && focusedRefLength > 0) {
      const selectedFixtureId = isObjectEmpty(selectedFixture)
        ? null
        : selectedFixture?.id;
      let focusedFixtureIndex = selectedFixtureId
        ? fixtures.findIndex((fixture) => fixture.id === selectedFixtureId)
        : fixtures.findIndex(
            (fixture) =>
              fixture.status === FIXTURE_CONSTANTS.FIXTURE_STATUS.IN_PROGRESS
          );
      if (focusedFixtureIndex === -1) {
        //IF NO CURRENT FIXTURE THEN FIND TODAY's FIXTURE
        focusedFixtureIndex = fixtures.findIndex(
          (fixture) => getRelativeDate(fixture.scheduleDate) === "Today"
        );
      }
      if (focusedFixtureIndex === -1) {
        //IF NO TODAY's FIXTURE THEN FIND Yesterday's FIXTURE
        focusedFixtureIndex = fixtures.findIndex(
          (fixture) => getRelativeDate(fixture.scheduleDate) === "Yesterday"
        );
      }
      if (focusedFixtureIndex === -1) {
        focusedFixtureIndex = 0;
      }
      if (fixturesRefs.current[focusedFixtureIndex]) {
        const focusedFixture = fixturesRefs.current[focusedFixtureIndex];
        if (focusedFixture) {
          focusedFixture.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center",
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtures]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center text-black my-[2m] text-[2em]">
        Loading...
      </div>
    );
  }
  if (fixtures?.length === 0) {
    return (
      <div className="flex justify-center items-center my-[2em]  text-black text-[2em]">
        No current fixtures found...
      </div>
    );
  }
  return (
    <>
      {fixtures &&
        Array.isArray(fixtures) &&
        fixtures.map((fixture, i) => (
          <div
            key={i}
            ref={(el) => (fixturesRefs.current[i] = el)}
            onClick={() => handleFixtureClick(i, fixture)}
            className="cursor-pointer"
          >
            <Fixture
              className={fixtureClass}
              style={fixtureStyle}
              bgClass={fixtureBgClass}
              fixture={fixture}
              isExpanded={isExpanded}
              isSelected={fixture?.id === selectedFixture?.id}
            />
          </div>
        ))}
    </>
  );
};

export default FixturesList;
