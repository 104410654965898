import React, { useContext } from "react";
import FixturesList from "./FixturesList";
import { appContext } from "../context/context";

const ScheduleSideBar = ({ expandedWidthClass, collapsedWidthClass }) => {
  const appState = useContext(appContext);
  const isExpanded = appState.isExpanded;

  return (
    <>
      <div
        className={` max-w-[100vw] bg-white overflow-y-scroll hide-scroll-bar overflow-x-hidden transition-all duration-700 ${
          isExpanded ? expandedWidthClass : collapsedWidthClass
        }`}
      >
        <FixturesList />
      </div>
    </>
  );
};

export default ScheduleSideBar;
