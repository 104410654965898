import axios from "axios";
import { BASE_URL, GET_FIXTURES_ENDPOINT } from "../constants/APIConstant";

const useGetSchedules = () => {
  const getSchedules = (callback) => {
    axios
      .get(BASE_URL + GET_FIXTURES_ENDPOINT)
      .then((response) => {
        callback(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        alert("Failed To Load Fixtures");
      });
  };
  return getSchedules;
};

export default useGetSchedules;
