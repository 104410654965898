import React from "react";
import ImageComponent from "./ImageComponent";
// import CustomizedTooltips from "../reusable/CustomizedTooltips";
import {
  CDN_IMAGE_PATH_FOR_NEWS,
  CDN_IMAGE_PATH_FOR_NEWS_IMAGE_NAME_IDENTIFIER,
  MAX_NO_OF_CHARACTERS_TO_SHOW_OF_DESCRIPTION_IN_NEWS_SECTION,
} from "../constants/EnvConstants";
import formatDate from "../functions/formatDate";
import { isMobileView } from "../constants/DeviceInfo";
import { useNavigate } from "react-router-dom";

function NewsCard({ news }) {
  const navigate = useNavigate();
  const handleReadMore = () => {
    navigate(`/news/${news.title}`, { state: { news } });
  };

  return (
    <div className="">
      <ImageComponent
        onClick={handleReadMore}
        className="rounded-2xl w-[400px] h-[250px] !object-cover cursor-pointer"
        style={{}}
        name={news.imageFileName}
        CDN_IMAGE_PATH={CDN_IMAGE_PATH_FOR_NEWS}
        CDN_IMAGE_PATH_IMAGE_NAME_IDENTIFIER={
          CDN_IMAGE_PATH_FOR_NEWS_IMAGE_NAME_IDENTIFIER
        }
      />

      <p className="mobile:my-[0.6em] mt-4 text-[#FFFFFFE6] laptop:text-[2.4em] tablet:text-[2.2em] mobile:text-[2em] text-[1.4em] laptop:w-[75%]">
        {news.title}
      </p>
      <div>
        <p className="laptop:text-[1.6em] tablet:text-[1.5em] mobile:text-[1.5em] text-[1.3em] text-[#8E92A5] inline ">
          {isMobileView
            ? news.description.slice(0, 40)
            : news.description.slice(
                0,
                MAX_NO_OF_CHARACTERS_TO_SHOW_OF_DESCRIPTION_IN_NEWS_SECTION
              )}
          ...
          <button
            style={{ textTransform: "capitalize" }}
            className={` text-[#19AAF880] font-[700] mobile:text-[1em] text-[0.9em] cursor-pointer "
                }`}
            onClick={handleReadMore}
          >
            Read More
          </button>
        </p>
      </div>

      <p className="mobile:mt-[1.5em] text-[1.3em] text-[#5B627C] ">
        {formatDate(news?.createdTimestamp)}
      </p>
    </div>
  );
}

export default NewsCard;
