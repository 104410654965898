import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import WicketIcon from "../assets/svgs/wicketBall.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { appContext } from "../context/context";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const getMaxOversOfTeam = (team) => {
  const innings = team?.inningDetails;
  if (innings) {
    return innings?.maxOvers;
  }
  return 0;
};

const getOversDataSetOfTeam = (team) => {
  const innings = team?.inningDetails;
  if (!innings) {
    return {};
  }
  const overDataset = innings?.overDataset;
  if (overDataset) {
    return overDataset;
  }
  return {};
};

const getRunsDataSetOfTeam = (team) => {
  const overDataset = getOversDataSetOfTeam(team);
  const runsDataSet = overDataset?.teamRuns;
  if (runsDataSet && Array.isArray(runsDataSet)) {
    return [0, ...runsDataSet];
  }
  return [];
};

const getWicketsDataSetOfTeam = (team) => {
  const overDataset = getOversDataSetOfTeam(team);
  const wicketsDataSet = overDataset?.teamWickets;
  if (wicketsDataSet && Array.isArray(wicketsDataSet)) {
    return wicketsDataSet;
  }
  return [];
};

function BattingGraph() {
  const appState = useContext(appContext);
  const fixture = appState.selectedFixtureDetailed;
  const teamsDetails = fixture?.teamsDetails;

  const teamAName = Array.isArray(teamsDetails)
    ? teamsDetails[0].teamName
    : "Team A";
  const teamBName = Array.isArray(teamsDetails)
    ? teamsDetails[1].teamName
    : "Team B";
  // Calculating Max Overs Possible
  const teamAMaxOvers = getMaxOversOfTeam(
    Array.isArray(teamsDetails) ? teamsDetails[0] : {}
  );
  const teamBMaxOvers = getMaxOversOfTeam(
    Array.isArray(teamsDetails) ? teamsDetails[1] : {}
  );
  const oversCount =
    teamAMaxOvers > teamBMaxOvers ? teamAMaxOvers : teamBMaxOvers;
  // Processing Runs Data
  const teamARuns = getRunsDataSetOfTeam(
    Array.isArray(teamsDetails) ? teamsDetails[0] : {}
  );
  const teamBRuns = getRunsDataSetOfTeam(
    Array.isArray(teamsDetails) ? teamsDetails[1] : {}
  );
  // Processing Wickets Data
  const teamAWickets = getWicketsDataSetOfTeam(
    Array.isArray(teamsDetails) ? teamsDetails[0] : {}
  );
  const teamBWickets = getWicketsDataSetOfTeam(
    Array.isArray(teamsDetails) ? teamsDetails[1] : {}
  );

  const data = {
    overs: Array.from({ length: oversCount }, (_, i) => i),
    // teamARuns: [
    //   0, 10, 20, 25, 35, 50, 65, 70, 80, 90, 95, 100, 110, 115, 125, 130, 135,
    //   140, 145, 150, 170,
    // ],
    teamARuns: teamARuns,
    // teamBRuns: [
    //   0, 14, 18, 24, 30, 45, 55, 60, 70, 85, 90, 95, 100, 105, 110, 115, 120,
    //   125, 130, 155, 170,
    // ],
    teamBRuns: teamBRuns,
    // teamAWickets: [3, 6, 6, 6, 6], // Example of multiple wickets in the same over
    teamAWickets: teamAWickets,
    // teamBWickets: [5, 10, 15, 19],
    teamBWickets: teamBWickets,
  };

  // Create combined data with wickets marked
  const createCombinedData = (runs, wickets) => {
    return runs.map((run, index) => {
      return {
        x: index, // Position for x-axis (overs)
        y: run,
        isWicket: wickets.includes(index),
        totalWickets: wickets.filter((over) => over <= index).length,
        wicketsInOver: wickets.filter((over) => over === index).length, // Count of wickets in the same over
      };
    });
  };

  const teamAData = createCombinedData(data.teamARuns, data.teamAWickets);
  const teamBData = createCombinedData(data.teamBRuns, data.teamBWickets);

  const chartData = {
    labels: data.overs, // Array of overs
    datasets: [
      {
        label: teamAName,
        data: teamAData,
        borderColor: "blue",
        // backgroundColor: "rgba(0, 0, 255, 0.1)",
        showLine: true,
        fill: false,
        tension: 0.4, // Add tension for spline effect
        pointRadius: teamAData.map((point) => (point.isWicket ? 5 : 0)), // Radius 5 for wickets, 0 for others
        pointBackgroundColor: "#FFFFFF", // White color for points
      },
      {
        label: teamBName,
        data: teamBData,
        borderColor: "green",
        backgroundColor: "rgba(0, 255, 0, 0.1)",
        showLine: true,
        fill: false,
        tension: 0.4, // Add tension for spline effect
        pointRadius: teamBData.map((point) => (point.isWicket ? 5 : 0)), // Radius 5 for wickets, 0 for others
        pointBackgroundColor: "#FFFFFF", // White color for points
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        border: {
          color: "#FFFFFF", // Color of the x-axis border line
        },
        grid: {
          display: false, // Hide x-axis grid lines
        },
        title: {
          display: true,
          text: "Overs",
          color: "#FFFFFF", // Color of the x-axis title
        },
        ticks: {
          color: "#FFFFFF", // Color of the x-axis ticks
          callback: function (value) {
            return value === 0 ? "Overs" : value; // Replace 0 with "Overs"
          },
          stepSize: 5, // Ensure intervals of 5 overs
        },
      },
      y: {
        type: "linear",
        position: "left",
        border: {
          color: "#FFFFFF", // Color of the y-axis border line
        },
        grid: {
          color: "#454E74", // Color of the y-axis grid lines
          display: true, // Display y-axis grid lines
        },
        title: {
          display: false,
          text: "Runs",
          color: "#FFFFFF", // Color of the y-axis title
        },
        ticks: {
          color: "#FFFFFF", // Color of the y-axis ticks
          stepSize: 70, // Gap of 70 runs between ticks
          callback: function (value) {
            return value === 0 ? "" : value; // Remove 0 from the y-axis
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "#FFFFFF", // Color of the legend labels
        },
        onClick: (e) => {}, // Disable legend click event
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            const dataPoint = context[0].raw;
            return `Over: ${dataPoint.x}`;
          },
          label: function (context) {
            const dataPoint = context.dataset.data[context.dataIndex];
            if (dataPoint.isWicket) {
              return [
                `Runs: ${dataPoint.y}`,
                `Total Wickets: ${dataPoint.totalWickets}`,
              ];
            }
            return `Runs: ${dataPoint.y}`;
          },
          labelTextColor: function () {
            return "#FFFFFF"; // Color of the tooltip text
          },
        },
        backgroundColor: "#333333", // Background color of the tooltip
        titleColor: "#FFFFFF", // Color of the tooltip title
        bodyColor: "#FFFFFF", // Color of the tooltip body
      },
    },
  };

  const wicketImage = new Image();
  wicketImage.src = WicketIcon; // Replace with your wicket image URL

  const customPlugin = {
    id: "customWicketPoints",
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;
      // Draw background color
      ctx.save();
      ctx.fillStyle = "#242A42";
      ctx.fillRect(
        chartArea.left,
        chartArea.top,
        chartArea.right - chartArea.left,
        chartArea.bottom - chartArea.top
      );
      ctx.restore();
    },
    afterDatasetDraw: (chart, args, options) => {
      const ctx = chart.ctx;
      const dataset = chart.getDatasetMeta(args.index).data;
      // Draw the wicket points
      dataset.forEach((point, index) => {
        const dataPoint = chart.data.datasets[args.index].data[index];
        if (dataPoint.isWicket) {
          for (let i = 0; i < dataPoint.wicketsInOver; i++) {
            ctx.drawImage(
              wicketImage,
              point.x - 10,
              point.y - 10 - i * 20,
              20,
              20
            );
          }
        }
      });
    },
    afterDraw: (chart) => {
      const ctx = chart.ctx;
      const xAxis = chart.scales["x"];
      ctx.save();
      ctx.strokeStyle = "#FFFFFF";
      ctx.lineWidth = 1;
      xAxis.ticks.forEach((tick, index) => {
        const x = xAxis.getPixelForValue(tick.value);
        ctx.beginPath();
        ctx.moveTo(x, chart.chartArea.bottom);
        ctx.lineTo(x, chart.chartArea.bottom - 10); // Adjust the height of the bar
        ctx.stroke();
      });
      ctx.restore();
    },
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "500px" }}>
      <Line data={chartData} options={options} plugins={[customPlugin]} />
    </div>
  );
}

export default BattingGraph;
