export const FIXTURES_REFRESH_MIN_INTERVAL_IN_SEC =
  process.env.REACT_APP_FIXTURES_REFRESH_MIN_INTERVAL_IN_SEC;
export const FIXTURES_REFRESH_MAX_INTERVAL_IN_SEC =
  process.env.REACT_APP_FIXTURES_REFRESH_MAX_INTERVAL_IN_SEC;
export const LIVE_DETAILED_FIXTURE_REFRESH_MIN_INTERVAL_IN_SEC =
  process.env.REACT_APP_LIVE_DETAILED_FIXTURE_REFRESH_MIN_INTERVAL_IN_SEC;
export const LIVE_DETAILED_FIXTURE_REFRESH_MAX_INTERVAL_IN_SEC =
  process.env.REACT_APP_LIVE_DETAILED_FIXTURE_REFRESH_MAX_INTERVAL_IN_SEC;
export const CDN_IMAGE_PATH_FOR_FLAGS =
  process.env.REACT_APP_CDN_IMAGE_PATH_FOR_FLAGS;
export const CDN_IMAGE_PATH_FOR_FLAGS_IMAGE_NAME_IDENTIFIER =
  process.env.REACT_APP_CDN_IMAGE_PATH_FOR_FLAGS_IMAGE_NAME_IDENTIFIER;
export const CDN_IMAGE_PATH_FOR_NEWS =
  process.env.REACT_APP_CDN_IMAGE_PATH_FOR_NEWS;
export const CDN_IMAGE_PATH_FOR_NEWS_IMAGE_NAME_IDENTIFIER =
  process.env.REACT_APP_CDN_IMAGE_PATH_FOR_NEWS_IMAGE_NAME_IDENTIFIER;
export const MAX_NO_OF_NEWS_TO_SHOW_WHEN_NEWS_SECTION_IS_COLLAPSED =
  process.env.REACT_APP_MAX_NO_OF_NEWS_TO_SHOW_WHEN_NEWS_SECTION_IS_COLLAPSED;
export const MAX_NO_OF_NEWS_TO_SHOW_WHEN_NEWS_SECTION_IS_EXPANDED =
  process.env.REACT_APP_MAX_NO_OF_NEWS_TO_SHOW_WHEN_NEWS_SECTION_IS_EXPANDED;
export const MAX_NO_OF_CHARACTERS_TO_SHOW_OF_DESCRIPTION_IN_NEWS_SECTION =
  process.env
    .REACT_APP_MAX_NO_OF_CHARACTERS_TO_SHOW_OF_DESCRIPTION_IN_NEWS_SECTION;
export const MAX_NO_OF_TOP_TEAMS_TO_SHOW_IN_LANDING_PAGE_WHEN_POINTS_TABLE_IS_COLLAPSED =
  process.env
    .REACT_APP_MAX_NO_OF_TOP_TEAMS_TO_SHOW_IN_LANDING_PAGE_WHEN_POINTS_TABLE_IS_COLLAPSED;
export const FETCH_FIXTURE_BY_ID_FROM_URI_FIXTURE_ID_PARAM_NAME =
  process.env.REACT_APP_FETCH_FIXTURE_BY_ID_FROM_URI_FIXTURE_ID_PARAM_NAME;
export const MAX_NO_OF_VIDEOS_TO_SHOW_BELOW =
  process.env.REACT_APP_MAX_NO_OF_VIDEOS_TO_SHOW_BELOW;
