import React, { useContext } from "react";
import { appContext } from "../context/context";

const TeamScoreStats = () => {
  const appState = useContext(appContext);
  const fixture = appState.selectedFixtureDetailed;
  const teamsDetails = fixture?.teamsDetails;
  if (Array.isArray(teamsDetails)) {
    return (
      <>
        {teamsDetails.map((team, i) => {
          const inningsList = team?.inningDetails;
          if (!inningsList || !Array.isArray(inningsList)) {
            return <div key={i} className="hidden"></div>;
          }
          return inningsList.map((inning, j) => {
            if (!inning) {
              return <div key={`${i}-${j}`} className="hidden"></div>;
            }
            const teamName = team?.teamName;
            const wicketsLost = inning?.wicketsLost || 0;
            const totalScore = inning?.totalRuns || 0;
            const oversPlayed = inning?.oversBowled || 0;
            const ballsPlayed = inning?.ballsBowled || 0;
            const fours = inning?.boundriesFours || 0;
            const sixes = inning?.boundriesSixes || 0;
            const totalBallsPlayed = oversPlayed * 6 + ballsPlayed;
            // const runRate = totalBallsPlayed
            //   ? ((totalScore * 100) / totalBallsPlayed).toFixed(2)
            //   : 0;
            const runRate = totalBallsPlayed
              ? (totalScore / (totalBallsPlayed / 6)).toFixed(2)
              : 0;

            return (
              <div
                key={`${i}-${j}`}
                className={`bg-[#FFFFFF26] border-cls  rounded-xl tablet:mx-[3.4em] mx-[1.9em] mt-[3.6em] tablet:px-[2.2em] px-[1.2em] py-[2em]`}
              >
                <div className="grid grid-cols-2 tablet:grid-cols-3 gap-y-8 laptop:grid-cols-5 text-[#ffffffE6] mobile:text-[1.6em] text-[1.2em]">
                  <p>{teamName}</p>
                  <p>
                    {wicketsLost}-{totalScore}&nbsp;
                    <span className="opacity-40">
                      ({oversPlayed}
                      {ballsPlayed ? `.${ballsPlayed}` : ""})
                    </span>
                  </p>
                  <p>
                    Fours
                    <span className="font-[600] mobile:text-[1.25em] text-[1em]">
                      &nbsp;{fours}
                    </span>
                  </p>
                  <p>
                    Sixes
                    <span className="font-[600] mobile:text-[1.25em] text-[1em]">
                      {" "}
                      &nbsp; {sixes}
                    </span>
                  </p>
                  <p>
                    Run Rate
                    <span className="font-[600] mobile:text-[1.25em] text-[1em]">
                      {" "}
                      &nbsp; {runRate}
                    </span>
                  </p>
                </div>
              </div>
            );
          });
        })}
      </>
    );
  }
  return <div></div>;
};

export default TeamScoreStats;
