import axios from "axios";
import { BASE_URL, GET_ALL_FIXTURES_ENDPOINT } from "../constants/APIConstant";

const useGetAllSchedules = () => {
  const getAllSchedules = (callback) => {
    axios
      .get(BASE_URL + GET_ALL_FIXTURES_ENDPOINT)
      .then((response) => {
        // callback(response?.data?.data);
        if (Array.isArray(response?.data?.data)) {
          callback(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Failed To Load Fixtures");
      });
  };
  return getAllSchedules;
};

export default useGetAllSchedules;
