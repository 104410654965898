import React, { useEffect, useState } from "react";
import defaultImage from "../assets/images/default-Image.png";
import {
  CDN_IMAGE_PATH_FOR_FLAGS,
  CDN_IMAGE_PATH_FOR_FLAGS_IMAGE_NAME_IDENTIFIER,
} from "../constants/EnvConstants";
const ImageComponent = ({
  name,
  className,
  paretnStyle,
  style,
  height,
  width,
  CDN_IMAGE_PATH,
  CDN_IMAGE_PATH_IMAGE_NAME_IDENTIFIER,
  ...props
}) => {
  const imagePath = CDN_IMAGE_PATH || CDN_IMAGE_PATH_FOR_FLAGS;
  const imageNameIdentifierInPath =
    CDN_IMAGE_PATH_IMAGE_NAME_IDENTIFIER ||
    CDN_IMAGE_PATH_FOR_FLAGS_IMAGE_NAME_IDENTIFIER;
  const IMAGE_PATH = imagePath.replace(imageNameIdentifierInPath, name);
  const [src, setSrc] = useState(IMAGE_PATH);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const IMAGE_PATH = imagePath.replace(imageNameIdentifierInPath, name);
    setSrc(IMAGE_PATH);
    // setIsLoaded(false); // Reset the loaded state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const handleError = () => {
    setSrc(defaultImage);
  };
  const handleLoad = () => {
    setIsLoaded(true);
  };

  return (
    <img
      src={isLoaded ? src : defaultImage}
      onError={handleError}
      onLoad={handleLoad}
      style={{
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
        ...style,
      }}
      alt=""
      className={className}
      height={height || "100%"}
      width={width || "100%"}
      {...props}
    />
  );
};

export default ImageComponent;
