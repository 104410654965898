import React, { useEffect, useState } from "react";
import useGetVideos from "../hooks/useGetVideos";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";
import { extractVideoId, getThumbnailUrl } from "../functions/youtubeFunctions";
import useGetTotalNoOfVideos from "../hooks/useGetTotalNoOfVideos";
import { MAX_NO_OF_VIDEOS_TO_SHOW_BELOW } from "../constants/EnvConstants";
import { isMobileView } from "../constants/DeviceInfo";
import ReadMore from "../reusable/ReadMore";
// import ReadMore from "../reusable/ReadMore";
const Videos = () => {
  const getVideos = useGetVideos();
  const getTotalNoOfVideos = useGetTotalNoOfVideos();
  const [videos, setVideos] = useState([]);
  const [totalNoOfVideos, setTotalNoOfVideos] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoLoading, setVideoLoading] = useState(false);
  const videosSessionStorageItemName = "all-videos-list";
  const totalNoOfVideosSessionStorageItemName = "total-no-of-videos";

  const setVideosCustom = (data) => {
    setVideos((prevVideos) => [...prevVideos, ...data]);
    // Convert data to JSON string
    const allVideosString = JSON.stringify([...videos, ...data]);
    // Save to session storage
    sessionStorage.setItem(videosSessionStorageItemName, allVideosString);
  };

  useEffect(() => {
    const allVideosString = sessionStorage.getItem(
      videosSessionStorageItemName
    );
    if (allVideosString) {
      const videosArray = JSON.parse(allVideosString);
      setVideos(videosArray);
      if (videosArray.length > 0 && !selectedVideo) {
        setSelectedVideo(videosArray[0]);
      }
    } else {
      getVideos(videos.length, MAX_NO_OF_VIDEOS_TO_SHOW_BELOW, (data) => {
        setVideosCustom(data);
        if (data.length > 0 && !selectedVideo) {
          setSelectedVideo(data[0]);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const totlaNoOfVideos = sessionStorage.getItem(
      totalNoOfVideosSessionStorageItemName
    );
    if (totlaNoOfVideos) {
      setTotalNoOfVideos(+totlaNoOfVideos);
    } else {
      getTotalNoOfVideos((data) => {
        setTotalNoOfVideos(data);
        sessionStorage.setItem(totalNoOfVideosSessionStorageItemName, data);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreVideos = () => {
    getVideos(videos.length, MAX_NO_OF_VIDEOS_TO_SHOW_BELOW, setVideosCustom);
  };

  const handleVideoSelect = (video) => {
    setVideoLoading(true);
    setTimeout(() => {
      setSelectedVideo(video);
      setVideoLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
    }, 500); // 500ms delay for transition effect
  };
  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + "...";
    }
    return text;
  };
  const filteredVideos = videos.filter(
    (video) => selectedVideo && video.url !== selectedVideo.url
  );

  return (
    <div className="youtube-playlist-container tablet:h-auto  ">
      <div className="video-player">
        {selectedVideo &&
          (videoLoading ? (
            <TailSpin color="#00BFFF" height={50} width={50} />
          ) : (
            <>
              <iframe
                className="laptop:min-h-[640px] min-h-[240px] w-[100%] min-w-[230px] "
                src={`https://www.youtube.com/embed/${extractVideoId(
                  selectedVideo?.url
                )}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                title={selectedVideo?.title}
              ></iframe>
              <div className="tablet:px-[2em] px-[1.4em]   mt-[1.5em] mobile:mb-[4em] mb-[2em] space-y-2 border-b-[1px] border-b-[#6C749933] mobile:pb-20 pb-8">
                <h1 className="tablet:text-[3.4em] mobile:text-[3em] text-[2.6em] text-[#ffffff80] font-[600] ">{`${selectedVideo?.title}`}</h1>
                <h1 className="tablet:text-[1.6em] mobile:text-[1.4em] text-[1.2em]  text-[#ffffff80] font-[300] ">
                  {new Date(selectedVideo?.uploadDateTime).toLocaleString()}
                </h1>
                {/* <h1 className="tablet:text-[1.8em] mobile:text-[1.6em] text-[1.4em]  text-[#ffffff80]  ">
                  {isMobileView
                    ? truncateText(selectedVideo?.description, 60)
                    : truncateText(selectedVideo?.description)}
                </h1> */}
                {isMobileView ? (
                  <ReadMore text={selectedVideo?.description} limit={160} />
                ) : (
                  <ReadMore text={selectedVideo?.description} limit={250} />
                )}
              </div>
            </>
          ))}
      </div>
      <div className="video-list tablet:px-[2em] px-[1.4em]">
        <h1 className="text-[#ffffffE6] mb-6 font-semibold tablet:text-[2.4em] text-[1.6em] ">
          Latest Videos{" "}
        </h1>
        <InfiniteScroll
          dataLength={filteredVideos.length}
          next={fetchMoreVideos}
          hasMore={totalNoOfVideos > videos.length}
          loader={<TailSpin color="#00BFFF" height={50} width={50} />}
          endMessage={
            <p className=" mt-7 text-center text tablet:text-[1.8em] mobile:text-[1.6em] text-[1.4em] text-[#ffffffCC] font-[600]">
              No more videos
            </p>
          }
        >
          <div className="video-grid">
            {filteredVideos.map((video, index) => {
              const videoId = extractVideoId(video.url);
              const thumbnail = getThumbnailUrl(videoId);
              return (
                <div
                  key={index}
                  className="video-item "
                  onClick={() => handleVideoSelect(video)}
                >
                  <div className="  ">
                    {thumbnail ? (
                      <img
                        src={thumbnail}
                        alt={video.title}
                        className="video-thumbnail object-contain  laptop:w-[180px] mobile:w-[160px] w-[130px] rounded-[10px] shadow"
                      />
                    ) : (
                      <div className="video-thumbnail-placeholder">
                        No Thumbnail
                      </div>
                    )}
                  </div>
                  <div className="pr-3  w-[70%] space-y-1">
                    <h3 className="video-title tablet:text-[1.8em] mobile:text-[1.6em] text-[1.2em] text-[#ffffff80] font-[600]">
                      {truncateText(video.title, 30)}
                    </h3>
                    <h3 className="tablet:text-[1.5em] mobile:text-[1.4em] text-[1.1em] text-[#ffffff80]">
                      {truncateText(video.description, 60)}
                    </h3>

                    <p className="tablet:text-[1.3em] mobile:text-[1.2em] text-[0.9em] text-[#ffffff4D] ">
                      {new Date(video.uploadDateTime).toLocaleString()}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Videos;
