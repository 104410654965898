import axios from "axios";
import { BASE_URL, GET_NEWS_ENDPOINT } from "../constants/APIConstant";
import { MAX_NO_OF_NEWS_TO_SHOW_WHEN_NEWS_SECTION_IS_EXPANDED } from "../constants/EnvConstants";

const useGetNews = () => {
  const getNews = (callback) => {
    axios
      .get(BASE_URL + GET_NEWS_ENDPOINT, {
        params: {
          page: 0, // Fetch Latest News
          size: MAX_NO_OF_NEWS_TO_SHOW_WHEN_NEWS_SECTION_IS_EXPANDED,
        },
      })
      .then((response) => {
        // console.log(response?.data?.data);
        // callback(response?.data?.data);
        if (Array.isArray(response?.data?.data)) {
          callback(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Failed To Load News");
      });
  };
  return getNews;
};

export default useGetNews;
