import React from "react";

function BtnContainer({ children, className, onClick, disabled, isClickable }) {
  return (
    <div
      onClick={onClick}
      className={`
      ${isClickable && "cursor-pointer"}
      ${
        isClickable && !disabled
          ? "bg-transparent border-opacity-0"
          : "bg-[#FFFFFF1A] border-opacity-100 border-cls"
      }  rounded-[67px]  w-full transition-all duration-700 text-center text-[#FFFFFF] mobile:text-[1.4em] text-[12px] tablet:pt-[1.25em] py-[1.25em] ${
        className || ""
      }
       ${isClickable && !disabled ? "opacity-60" : ""}`}
    >
      {children}
    </div>
  );
}
export default BtnContainer;
