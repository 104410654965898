import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Slug } from "./constants/Slug";
import AppState from "./context/AppState";
// Set scroll restoration to manual
if ("scrollRestoration" in window.history) {
  window.history.scrollRestoration = "manual";
}
const useStrictMode = process.env.REACT_APP_USE_STRICT_MODE === "true";
const root = ReactDOM.createRoot(document.getElementById("root"));

const rootChildren = (
  <BrowserRouter basename={Slug}>
    <AppState>
      <App />
    </AppState>
  </BrowserRouter>
);

root.render(
  useStrictMode ? (
    <React.StrictMode>{rootChildren}</React.StrictMode>
  ) : (
    rootChildren
  )
);
