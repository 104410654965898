const FIXTURE_CONSTANTS = {
  FIXTURE_STATUS: {
    SCHEDULED: 0,
    SCHEDULED_SQUAD_SELECTED: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
  },
  PLAYER_ROLE: {
    NORAML: 0,
    VICE_CAPTIAN: 1,
    CAPTIAN: 2,
  },
  WICKET_KEEPER_ROLE: {
    FALSE: 0,
    TRUE: 1,
  },
  BATSMAN_BATTING_STATUS: {
    NOT_OUT: 0,
    BOWLED: 50,
    RUN_OUT: 51,
    CATCH_OUT: 52,
    LBW: 53,
    HIT_WICKET: 55
  }
};
export default FIXTURE_CONSTANTS;
