import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  BaseRoute,
  NewsRoute,
  PointsTableRoute,
} from "../constants/RouteConstants";
import Header from "../components/landing/Header";
import bannerWorlcup from "../assets/images/bannerLiveScore.png";
import bannerWorlcupsm from "../assets/images/bannerLiveScoreSm.png";
import { isMobileView } from "../constants/DeviceInfo";
import PointsTable from "../components/PointsTable";
import News from "../components/News";
import Arrow from "../assets/svgs/rightArrow.svg";
import { MAX_NO_OF_TOP_TEAMS_TO_SHOW_IN_LANDING_PAGE_WHEN_POINTS_TABLE_IS_COLLAPSED } from "../constants/EnvConstants";
import { appContext } from "../context/context";

function Landing() {
  const appState = useContext(appContext);

  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate(BaseRoute);
  };
  const navigateToPointsTable = () => {
    navigate(PointsTableRoute);
  };

  return (
    <>
      <div
        style={{
          background: "linear-gradient(180deg, #212539 0%, #354070 100%) ",
        }}
        className="bg-black min-h-screen pb-[2em] "
      >
        <div className="-fixed top-0- w-full z-[10000] ">
          <Header selectedPage={"Home"} />
        </div>
        <div className="pt-[30em]- pt-[6em] mobile:pt-[10em]">
          <div className=" flex justify-center tablet:px-[10em] mobile:px-[6em] px-[1em] ">
            {!isMobileView ? (
              <img
                className="w-full object-contain cursor-pointer  "
                onClick={navigateToHome}
                src={bannerWorlcup}
                alt=""
              />
            ) : (
              <img
                src={bannerWorlcupsm}
                className="cursor-pointer"
                alt=""
                onClick={navigateToHome}
              />
            )}
          </div>
          <div className="tablet:px-[10em] mobile:px-[6em] px-[3em]">
            {" "}
            <div
              className="rounded-xl  mobile:mt-[3.1em] mt-[2.5em]  tablet:pt-[3em] mobile:pt-[1.3em] pb-[4em]"
              ref={appState?.targetRef}
            >
              <News handleViewAll={() => navigate(NewsRoute)} />
            </div>
            {/* added hidden property to hide points table */}
            <div className={`rounded-xl hidden `}>
              <div>
                <div className="flex items-center space-x-3">
                  <div className="laptop:text-[3.6em] tablet:text-[3em] mobile:text-[2.8em] text-[1.4em]  text-[#FFFFFF]">
                    Points Table
                  </div>
                  <button
                    className="flex items-center cursor-pointer hover:opacity-85 gap-x-3 laptop:text-[1.6em] tablet:text-[1.4em] mobile:text-[1.3em] text-[1em]  text-[#FFFFFF80] "
                    onClick={navigateToPointsTable}
                  >
                    View All
                    <img
                      className="mobile:w-auto w-2"
                      src={Arrow}
                      alt="arrow"
                    />
                  </button>
                </div>
              </div>
              <PointsTable
                size={
                  MAX_NO_OF_TOP_TEAMS_TO_SHOW_IN_LANDING_PAGE_WHEN_POINTS_TABLE_IS_COLLAPSED
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
