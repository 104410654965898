function getFormattedTime(timeString) {
  const [hours, minutes] = timeString.split(":");
  let period = "AM";
  let hour = parseInt(hours, 10);

  if (hour === 0) {
    hour = 12;
  } else if (hour === 12) {
    period = "PM";
  } else if (hour > 12) {
    hour -= 12;
    period = "PM";
  }

  return `${hour}:${minutes} ${period}`;
}

export default getFormattedTime;
