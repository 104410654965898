import React from "react";
import landingBackground from "../assets/images/landingBackground.jpg";
// import BackToHomeHeader from "../components/BackToHomeHeader";
import PointsTableComponent from "../components/PointsTable";
import Header from "../components/landing/Header";

const PointsTable = () => {
  return (
    <section
      className="bg-opacity-80 w-full min-h-screen "
      style={{
        backgroundImage: `url(${landingBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "top",
      }}
    >
      {/* <BackToHomeHeader title={"Points Table"} navigateBack={true} /> */}
      <div className="-fixed top-0- w-full z-[10000] ">
        <Header selectedPage={"pointsTable"} />
      </div>
      <div className="tablet:px-[10em] mobile:px-[6em] px-[2em] mobile:py-[3.2em] py-[1.2em]">
        <PointsTableComponent />
      </div>
    </section>
  );
};
export default PointsTable;
