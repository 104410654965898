import React, { useContext } from "react";
import toggleBtnSvg from "../assets/svgs/toggleBtn.svg";
// import telenorLogoSvg from "../assets/svgs/telenor.svg";
import homeicon from "../assets/images/home.png";
import ScheduleSideBar from "../components/ScheduleSideBar";
import HomeOutlet from "../components/HomeOutlet";
import { useNavigate } from "react-router-dom";
import {
  PointsTableRoute,
  LandingRoute,
  ScheduleRoute,
} from "../constants/RouteConstants";
import { isTabletViewOrLess } from "../constants/DeviceInfo";
import { appContext } from "../context/context";
import fullScreenSvg from "../assets/svgs/fullScreen.svg";

const expandedWidthClass = isTabletViewOrLess ? "w-[100vw]" : `w-[39.5em]`;
const collapsedWidthClass = "w-[0em]";

function Home() {
  const appState = useContext(appContext);
  const isExpanded = appState.isExpanded;
  const setIsExpanded = appState.setIsExpanded;
  const navigate = useNavigate();

  const navigateToPointsTable = () => {
    navigate(PointsTableRoute);
  };

  const navigateToHome = () => {
    navigate(LandingRoute);
  };

  const toggleExpandeCollapse = () => {
    setIsExpanded(!isExpanded);
  };
  const onExpandClick = () => {
    navigate(ScheduleRoute);
  };

  return (
    <div className="">
      <div className="fixed w-full overflow-x-hidden z-[1000] ">
        <div className="">
          <ToggelBtn onClick={toggleExpandeCollapse} />
          <div className="flex justify-between w-full">
            <div className="overflow-hidden bg-white">
              <div
                className={`bg-white transition-all duration-700  ${
                  isExpanded ? expandedWidthClass : collapsedWidthClass
                }`}
              >
                <div className="px-[2.4em] py-[2em]  flex justify-end items-center">
                  {/* <img
                    className="min-h-[3.63em] min-w-[12.7em]"
                    src={telenorLogoSvg}
                    alt="X"
                  /> */}
                  <img
                    onClick={navigateToHome}
                    className="w-10 h-10 cursor-pointer hover:scale-105 opacity-75"
                    src={homeicon}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className={` transform-all duration-[1000ms]
              ${
                isTabletViewOrLess
                  ? isExpanded
                    ? "w-0"
                    : "w-[100vw]"
                  : "flex-grow"
              } `}
            >
              {/* added hidden property to hide points table */}
              <div className="hidden text-[#6C7499] mobile:text-[1.6em] text-[1.4em] font-medium text-right pr-[2.6em] py-[1.45em] flex-grow border-b-[1px] border-b-[#6C74991A]  ">
                <button
                  className=" cursor-pointer whitespace-nowrap overflow-hidden "
                  onClick={navigateToPointsTable}
                >
                  Points Table
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="flex w-full bg-transparent ">
        <div
          className={`max-h-screen-min-h-screen h-screen flex flex-col items-center justify-start ${
            isTabletViewOrLess && isExpanded ? "" : ""
          }`}
        >
          <div>
            <div
              className={`${
                isTabletViewOrLess ? "" : ""
              } transition-all duration-700 whitespace-nowrap ${
                isExpanded ? expandedWidthClass : collapsedWidthClass
              }`}
            ></div>
            <div
              className={` max-w-[100vw] bg-white overflow-y-scroll  hide-scroll-bar overflow-x-hidden transition-all duration-700 ${
                isExpanded ? expandedWidthClass : collapsedWidthClass
              }`}
            >
              <ScheduleDiv
                isExpanded={isExpanded}
                onExpandClick={onExpandClick}
              />
            </div>
          </div>
          <ScheduleSideBar
            expandedWidthClass={expandedWidthClass}
            collapsedWidthClass={collapsedWidthClass}
          />
        </div>
        <div className="overflow-y-scroll hide-scroll-bar max-h-screen flex-grow bg-[#212539]">
          <HomeOutlet />
        </div>
      </section>
    </div>
  );
}

const ToggelBtn = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`cursor-pointer absolute top-[1.75em] left-[1.45em] z-50 p-3`}
    >
      <img src={toggleBtnSvg} alt="X" />
    </div>
  );
};

const ScheduleDiv = ({ isExpanded, onExpandClick, className }) => {
  return (
    <div
      className={`${isExpanded ? "px-[2.2em] " : "px-0"}
      ${
        className || ""
      } py-[1.45em] bg-[#19AAF8]  overflow-hidden transition-all duration-1000 w-full flex justify-between mt-[6.8em]`}
    >
      <p className="text-white text-[1.5em] leading-[1.8em]">Schedule</p>
      <div className="overflow-hidden cursor-pointer" onClick={onExpandClick}>
        <img className="min-h-[2em] min-w-[2em]" src={fullScreenSvg} alt="X" />
      </div>
    </div>
  );
};
export default Home;
