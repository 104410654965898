import React, { useContext, useRef } from "react";
import chevronIcon from "../../assets/svgs/chevronIcon.svg";
import FixturesList from "../FixturesList";
import { isTabletViewOrLess } from "../../constants/DeviceInfo";
import { appContext } from "../../context/context";
import { useNavigate } from "react-router-dom";
import { ScheduleRoute } from "../../constants/RouteConstants";
function HeaderFixtures() {
  const appState = useContext(appContext);
  const fixtures = appState?.allFixtures;
  const fixturesLength = Array.isArray(fixtures) ? fixtures.length : 0;
  const containerRef = useRef();

  const ITEM_WIDTH = 400;

  const handleScroll = (scrollAmount) => {
    containerRef.current.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        className="flex items-center bg-[#f5f5f5] tablet:px-7 space-x-6"
        style={
          !isTabletViewOrLess ? { borderRadius: "0px 0px 16.96px 16.96px" } : {}
        }
      >
        <img
          className=" mobile:static absolute left-1 cursor-pointer hover:opacity-75 hover:scale-105"
          onClick={() => handleScroll(-ITEM_WIDTH * 2)}
          src={chevronIcon}
          alt=""
        />

        <div
          className="container-main hide-scroll-bar  "
          ref={containerRef}
          style={{ width: "100%", overflowX: "auto" }}
        >
          <FixturesList
            fixtureBgClass={"bg-[#f5f5f5]"}
            fixtureClass={
              "card  w-[30em] min-w-[30em] max-w-[30em] tablet:w-[38.5em] tablet:min-w-[38.5em] tablet:max-w-[38.5em] min-h-[157px] tablet:min-h-[189px]"
            }
          />
          {fixturesLength > 0 && <ShowAllFixturesCard />}
        </div>

        <img
          onClick={() => handleScroll(ITEM_WIDTH * 2)}
          className="mobile:static rotate-180 absolute right-1  cursor-pointer hover:opacity-75 hover:scale-105"
          src={chevronIcon}
          alt=""
        />
      </div>
    </>
  );
}
const ShowAllFixturesCard = () => {
  const navigate = useNavigate();
  const navigateToSchedule = () => {
    navigate(ScheduleRoute);
  };
  return (
    <div
      onClick={navigateToSchedule}
      className="cursor-pointer bg-[#f5f5f5] flex justify-center items-center border-r-[1px] border-[#6C74991A] w-[19.25em] min-w-[19.25em] max-w-[19.25em] min-h-[157px] tablet:min-h-[189px]"
    >
      <div className="text-[1.6em] ">View All...</div>
    </div>
  );
};

export default HeaderFixtures;
