import React, { useContext, useState } from "react";
import LightedSection from "./LightedSection";
import profileIcon from "../assets/svgs/profileIcon.svg";
import BtnContainer from "../reusable/BtnContainer";
import { appContext } from "../context/context";
import FixtureConstants from "../constants/FixtureConstants";
function Squads() {
  const appState = useContext(appContext);
  const fixture = appState.selectedFixtureDetailed;

  const [selectedTeamNo, setSelectedTeamNo] = useState(0);
  const selectFirstTeam = () => {
    setSelectedTeamNo(0);
  };
  const selectSecondTeam = () => {
    setSelectedTeamNo(1);
  };

  let squad = [];
  if (
    fixture &&
    Array.isArray(fixture?.teamsDetails) &&
    Array.isArray(fixture?.teamsDetails[selectedTeamNo].squad)
  ) {
    squad = fixture?.teamsDetails[selectedTeamNo].squad;
  }

  return (
    <>
      <section className="">
        <div className="tablet:mx-[2.8em] ">
          <BtnContainer>Squads</BtnContainer>
          <div className="flex items-center justify-center w-full  mt-[3.1em] space-x-4">
            <BtnContainer
              className={"cursor-pointer"}
              onClick={selectFirstTeam}
              disabled={selectedTeamNo === 0}
              isClickable={true}
            >
              {fixture &&
                Array.isArray(fixture?.teamsDetails) &&
                fixture?.teamsDetails[0]?.teamName}
            </BtnContainer>
            <BtnContainer
              className={"cursor-pointer"}
              onClick={selectSecondTeam}
              disabled={selectedTeamNo === 1}
              isClickable={true}
            >
              {fixture &&
                Array.isArray(fixture?.teamsDetails) &&
                fixture?.teamsDetails[1]?.teamName}
            </BtnContainer>
          </div>
        </div>
        <LightedSection>
          {squad.length === 0 ? (
            <div className="flex justify-center items-center text-[#ffffff80] my-[3em] mobile:text-[2em] text-[1.3em] font-[500]">
              Squad not announced yet...
            </div>
          ) : (
            <ul>
              {squad.map((item, i) => (
                <div
                  key={i}
                  className={`px-[1.8em] ${
                    i % 2 !== 0 && "rounded-[10px] bg-[#FFFFFF1A] "
                  }`}
                >
                  {" "}
                  <li className="py-[2em] flex items-center space-x-[1.2em] ">
                    <img
                      src={profileIcon}
                      alt={`${item.text} logo`}
                      className=""
                    />
                    <div>
                      <h1 className="tablet:text-[1.6em] mobile:text-[1.4em] text-[1.1em]  text-[#FFFFFFE6] ">
                        {item.playerName}
                        {item.isWicketKeeper ===
                          FixtureConstants.WICKET_KEEPER_ROLE.TRUE && " (WK)"}
                        {item.role === FixtureConstants.PLAYER_ROLE.CAPTIAN &&
                          " (C)"}
                      </h1>
                      <span className="tablet:text-[1.5em] text-[1.2em] text-[#FFFFFF80] ">
                        {i < 5 ? item.battingStyle : item.bowlingStyle}
                      </span>
                    </div>
                  </li>
                </div>
              ))}
            </ul>
          )}
        </LightedSection>
      </section>
    </>
  );
}

export default Squads;
