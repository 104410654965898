import React from "react";
// import BackToHomeHeader from "../components/BackToHomeHeader";
import VideosComponent from "../components/Videos";
import Header from "../components/landing/Header";

function Videos() {
  return (
    <div
      style={{
        background: "linear-gradient(180deg, #212539 0%, #354070 100%)",
      }}
      className=" min-h-screen pb-[2em] "
    >
      {/* <BackToHomeHeader title={"Videos"} navigateBack={true} /> */}
      <div className="-fixed top-0- w-full z-[10000] ">
        <Header selectedPage={"videos"} />
      </div>
      <VideosComponent />
    </div>
  );
}

export default Videos;
